.Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--dark-blue);
    padding: 15px 20px;
    position: fixed;
    z-index: 20;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.9);
}

.Header .logo {
    color: white;
    font-weight: bold;
    font-size: 2rem;
}

.Header .logo span {
    color: var(--blueish-purple);
}

.hamburger-menu {
    width: 30px;
    height: 30px;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    cursor: pointer;
    z-index: 21;
}

.hamburger-menu div {
    width: 100%;
    height: 4px;
    background-color: var(--blueish-purple);
    transition: 0.4s;
}

.hamburger-menu.open .bar1 {
    transform: rotate(-45deg) translate(-10px, 3px);
}

.hamburger-menu.open .bar2 {
    opacity: 0;
}

.hamburger-menu.open .bar3 {
    transform: rotate(45deg) translate(-15px, -8px);
}

.Header .menu-open a {
    background: none;
    color: white;
    border: none;
    font-family: "Poppins";
    text-decoration: none;
}

.Header .menu-open>*:hover {
    color: var(--blueish-purple);
    cursor: pointer;
}

.Header .orange-circle {
    display: none;


}

.Header .blue-circle-header {
    display: none;
    width: 60px;
    height: 60px;
    margin-right: 50px;
    background-color: var(--blueish-purple);
    border-radius: 50%;
    flex-shrink: 0;
    position: relative;
}

.Header .menu-open {
    display: flex;
    position: absolute;
    top: 50px;
    height: 0%;
    flex-direction: column;
    overflow: hidden;
    transition: height 0.2s ease-in-out;
}


/* RESPONSIVE */

@media (min-width:768px) and (max-width:1024px) {

    .Header .hamburger-menu {
        display: none;
    }

    .Header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: var(--dark-blue);
        padding: 1em;
        padding-left: 2em;
        position: relative;
        z-index: 30;
        width: 100%;
        box-shadow: none;

    }

    .Header .menu-open {
        height: 100% !important;
        color: white;
        position: relative;
        top: 0px;
        margin-left: 3rem;
        padding: 0 1em;
        background-color: var(--dark-blue);
        display: flex;
        flex-direction: row;
        gap: 2em;

    }


}

/* Desktop */

@media (min-width: 1024px) {

    .Header .blue-circle-header {
        display: block;
    }

    .Header .orange-circle {
        display: block;
        z-index: 1;
        position: relative;
        bottom: 20px;
    }

    .Header {
        display: flex;
        background-color: var(--dark-blue);
        position: relative;
        z-index: 30;
        width: 100%;
        box-shadow: none;
        padding: 20px 80px;

    }

    .Header .hamburger-menu {
        display: none;
    }

    .Header .menu-open {
        height: 100% !important;
        color: white;
        position: relative;
        top: 0px;
        padding: 0 1em;
        background-color: var(--dark-blue);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-left: 7rem;
        width: 70%;

    }

    .Header .logo {
        color: white;
        font-weight: bold;
        font-size: 3.5rem;
    }

    .menu-open>* {
        font-size: 1.5rem;
    }

    .Header .nav_circles {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        position: relative;
        z-index: 90;
    }

}