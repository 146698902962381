.Contact {
    height: 100vh;
    background-color: var(--dark-blue);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
    overflow: hidden;
    position: relative;
}

.Contact .contact_title {
    font-size: 2.5rem;
    text-transform: uppercase;
    padding-top: 50px;
    color: white;
}

.Contact .communication-hub {
    display: flex;
    flex-direction: column;
    gap: 2em;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.Contact .communication-hub form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2em;
    background-color: var(--dark-grey);
    width: fit-content;
    padding: 2em;
    border-radius: 20px;
    width: 80%;
}

.Contact .communication-hub form input,
.Contact .communication-hub form textarea {
    border: none;
    color: var(--grey);
    width: 100%;
    font-size: 20px;
    padding: 1em;
    border-radius: 10px;
    font-family: "Poppins", sans-serif;
}

.Contact .communication-hub form textarea {
    height: 200px;
}

.Contact .communication-hub .send-btn {
    border: none;
    background-color: var(--blueish-purple);
    color: white;
    border-radius: 5px;
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    justify-self: flex-start;
    text-transform: uppercase;
    width: 50%;

}

.Contact .communication-hub .send-btn:hover {
    background: white;
    color: var(--blueish-purple);
    cursor: pointer;


}

.popup-message {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 1em 2em;
    border-radius: 10px;
    z-index: 1000;
}

.popup-message p {
    font-size: 1.2rem;
    text-align: center;
}


.Contact .communication-hub form input::placeholder,
.Contact .communication-hub form textarea::placeholder {
    color: #C3C3C3;
    font-weight: bold;
}

.Contact .communication-hub .social-icons-container {
    color: white;
    font-size: 3em;
    gap: 1em;
    display: flex;
    align-items: center;
    color: var(--grey);
}

.Contact .communication-hub .social-icons-container>*:hover {
    cursor: pointer;
    color: var(--blueish-purple)
}

.Contact .communication-hub .faSquareWhatsapp {
    background-color: var(--dark-blue);
}

.Contact .mobile-blue-circle {
    position: absolute;
    bottom: 0;
    right: 30px;
}

.Contact .communication-hub .mobile-small-grey-circle {
    position: relative;
    bottom: 140px;
    left: 120px;
}


.Contact .social-icons-container .whatsapp-container p,
.Contact .social-icons-container .email-container p {
    display: none;
}

.Contact .desktop-blue-circle {
    display: none;
}

.Contact .or-word {
    display: none;
}

.Contact .desktop-grey-circle {
    display: none;
}

/* RESPONSIVE */


/* 360px */
@media (max-width:360px) {
    .Contact .contact_title {
        font-size: 2rem;
        text-transform: uppercase;
        padding-top: 50px;
        color: white;
    }

    .Contact .communication-hub form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1em;
        background-color: var(--dark-grey);
        width: fit-content;
        padding: 2em;
        border-radius: 20px;
        width: 80%;
    }

    .Contact .communication-hub form textarea {
        height: 100px;
    }

    .Contact .communication-hub form input,
    .Contact .communication-hub form textarea {
        border: none;
        color: var(--grey);
        width: 100%;
        font-size: 10px;
        padding: 1em;
        border-radius: 10px;
        font-family: "Poppins", sans-serif;
    }

    .Contact .communication-hub .send-btn {
        font-size: 10px;

    }

}

/* 375px */

@media (min-width: 361px) and (max-width: 375px) {

    .Contact .contact_title {
        font-size: 1.6rem;
        text-transform: uppercase;
        padding-top: 50px;
        color: white;
    }


    .Contact .communication-hub form input,
    .Contact .communication-hub form textarea {
        border: none;
        color: var(--grey);
        width: 100%;
        font-size: 10px;
        padding: 1em;
        border-radius: 10px;
        font-family: "Poppins", sans-serif;
    }

    .Contact .communication-hub form {
        gap: 1em;
    }

    .Contact .communication-hub form textarea {
        height: 100px;
    }

    .Contact .communication-hub .send-btn {
        font-size: 10px;


    }

    .Contact .communication-hub .social-icons-container {
        font-size: 2em;

    }


    .Contact .mobile-blue-circle {
        position: relative;
        bottom: 0;
        right: 100px;
        bottom: 20px;
        border: 5px solid red;
    }


    .Contact .communication-hub .mobile-small-grey-circle {
        position: relative;
        bottom: 140px;
        left: 120px;
    }

}

/* 380-390 */

@media (min-width: 380px) and (max-width: 390px) {

    .Contact .contact_title {
        font-size: 2rem;
        text-transform: uppercase;
        padding-top: 50px;
        color: white;
    }

    .Contact .communication-hub form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2em;
        background-color: var(--dark-grey);
        width: fit-content;
        padding: 2em;
        border-radius: 20px;
        width: 80%;
    }

    .Contact .communication-hub form input,
    .Contact .communication-hub form textarea {
        border: none;
        color: var(--grey);
        width: 100%;
        font-size: 15px;
        padding: 1em;
        border-radius: 10px;
        font-family: "Poppins", sans-serif;
    }

    .Contact .communication-hub .send-btn {
        font-size: 15px;

    }

}

@media (min-width: 1024px) {

    .Contact {
        padding: 1rem 12rem;
        align-items: center;
    }

    .Contact .contact_title {
        align-self: self-start;
        position: relative;
    }

    .Contact .contact_title::after {
        content: "";
        display: block;
        position: absolute;
        right: -110px;
        bottom: 15px;
        width: 100px;
        height: 1px;
        background-color: white;

    }
}

@media (min-width:1024px) {

    .Contact .communication-hub {
        flex-direction: row-reverse;
        justify-content: space-between;

    }

    .Contact .communication-hub .social-icons-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .Contact .communication-hub form {
        width: 30%;

    }

    .Contact .social-icons-container .whatsapp-container p,
    .Contact .social-icons-container .email-container p {
        display: block;
        font-size: 0.5em;

    }

    .Contact .social-icons-container .whatsapp-container,
    .Contact .social-icons-container .email-container {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-start;
        gap: 0.3em;
    }

    .Contact .or-word {
        display: block;
        text-transform: uppercase;
        color: var(--blueish-purple);
        font-weight: bold;
        font-size: 3rem;

    }


    .Contact .mobile-blue-circle,
    .Contact .mobile-small-grey-circle {
        display: none;
    }

    .Contact .desktop-grey-circle {
        position: absolute;
        bottom: 0px;
        left: 100px;
    }


    .Contact .communication-hub form input,
    .Contact .communication-hub form textarea {
        color: black;
    }

    .Contact .desktop-blue-circle {
        display: block;
        position: relative;
        left: 300px;
        bottom: -50px;
        width: 80%;
    }

    .Contact .linkedin-container {
        display: flex;
    }


}