.MyProjects {
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--grey);
    position: relative;

    /* ntc when I got more than three projects what should the component do? scrolling down maybe? it's better to see what is the most common thing to do in that case */
}

.MyProjects .myproject-title::after {
    content: "";
    display: block;
    position: absolute;
    right: -110px;
    bottom: 15px;
    width: 100px;
    height: 1px;
    background-color: black;

}

.MyProjects .myproject-title {
    font-size: 2.5rem;
    text-transform: uppercase;
    padding-top:  50px;
    margin-bottom: 1em;
    position: relative;
}

.MyProjects .arrow {
    display: none;
}

.MyProjects .projects-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;

}

.MyProjects .project-box {
    height: 200px;
    width: 100%;
    border-radius: 38px;
    border: 10px white solid;
    display: flex;
    overflow: hidden;

}

.MyProjects .project-box:hover {
    cursor: pointer;

}

.MyProjects .project-box img {
    height: 100%;
    width: 100%;
    object-fit: cover;

}

.MyProjects .project-desc {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translateY(-50%) translateX(50%);
    z-index: 20;
    opacity: 0;
}

.MyProjects .project-headline {
    color: white;
    text-transform: capitalize;
}

.MyProjects .project-sub-head {
    color: var(--orange);
    text-transform: capitalize;
    background-color: black;
    padding: 0 1em;
}

.MyProjects .project-box:hover .project-desc {
    opacity: 1;

}


.MyProjects .project-box img:hover {
    filter: brightness(50%);
}

.MyProjects .white-circle,
.MyProjects .points {
    display: none;
}



/* RESPONSIVE */

/* moblie */

@media (max-width: 700px) {
    .MyProjects .myproject-title::after {
        display: none;
    }
}

/* Desktop */

@media (min-width: 1024px) {

    .MyProjects .white-circle,
    .MyProjects .points {
        display: block;
        position: absolute;

    }

    .MyProjects .white-circle {
        top: 0px;
        right: 0px;
    }


    .MyProjects .points {
        bottom: -10px;
        right: 400px;
    }


    .MyProjects {
        padding: 1rem 12rem;
        justify-content: center;
    }

    .MyProjects .myproject-title {
        font-size: 2.5rem;
        text-transform: uppercase;
        padding-top: 0px;
        margin-bottom: 1em;
        align-self: start;
    }

    .MyProjects .projects-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 60px;
    }

    .MyProjects .project-box {
        height: 662px;
        max-width: 421px;
        border-radius: 38px;
        border: 5px var(--dark-blue) solid;
        display: flex;
        overflow: hidden;
        position: relative;

    }

    .MyProjects button {
        border-radius: 50%;
        padding: 1em;
        border: white solid 5px;
        background-color: rgb(194, 194, 194);
    }

    .MyProjects button:hover {
        cursor: pointer;
        background-color: rgb(124, 123, 123);
        transform: scale(1.1);

    }

    .MyProjects button:hover .arrow {
        color: white;
    }


    .MyProjects .left {
        transform: scale(-100%);
    }

    .MyProjects .arrow {
        display: block;
        font-size: 4em;
        color: var(--dark-blue)
    }

}