*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --blueish-purple: #6F5FFE;
  --dark-blue: #272F3D;
  --grey: #F0EEEA;
  --dark-grey: #D9D9D9;
  --dark-black: #1F242D;
  --orange: #E7AC67;
}

body {
  height: 100vh;
  width: 100%;
  font-family: "Poppins", sans-serif;

}

html,
body {
  scroll-behavior: smooth;
}


#root {
  height: 100%;

}