.About {
    height: 100vh;
    text-align: center;
    color: white;
    background-color: var(--dark-blue);
    padding-top: 180px;
}

.About .text-con {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: flex-start;
    gap: 20px;
    padding: 0 1em;
}

.About .about_intro {
    font-size: 1.5rem;
}

.About .about_intro span {
    font-weight: bold;
    color: var(--blueish-purple);
}

.About .about_intro .point {
    font-weight: bold;
    color: white;
}

.About .about_title {
    text-transform: capitalize;
    font-size: 3rem;
    line-height: 3rem;
}

.About .about_description {
    padding: 0 3em;
}

.About .about-btn {
    color: white;
    font-weight: bold;
    background-color: var(--blueish-purple);
    padding: 0.5em 2em;
    border-radius: 30px;
    font-size: 1.2rem;
    text-decoration: none;
}

.About .img-cont {
    display: flex;
    align-items: center;
}

.About .grey-circle {
    position: relative;
    top: 150px;
    right: 50px;
}

.About .visual-side {
    display: none;
}

.About .orange-circle-about-no-tail {
    display: none;

}

.About .blue-circle-about,
.About .grey-circle-about {
    display: none;

}

/* Responsive Breakpoints */

/* max 375px*/

@media (max-width: 375px) {

    .About {

        padding-top: 150px;
    }

    .About .about_intro {
        font-size: 1rem;
    }

    .About .about_title {
        text-transform: capitalize;
        font-size: 2rem;
        line-height: 2.5rem;
    }

    .About .about_description {
        padding: 0 3em;
        font-size: 0.8rem;
    }

    .About .about-btn {
        font-size: 0.8rem;
    }

    .About .orange-circle {
        position: relative;
        bottom: 80px;
    }

    .About .grey-circle {
        top: 40px;
    }

}

/* max 390px */
@media (max-width: 390px) {
    .About .about_intro {
        font-size: 1.3rem;
    }

    .About .about_title {
        font-size: 2rem;
        line-height: 2rem;
    }

}

/* max 360 */

@media (max-width: 360px) {

    .About .grey-circle {
        position: relative;
        top: 120px;
        right: 50px;
    }
}

/* TABLET AND IPADS */

/*768px to 1024px */

@media (min-width:768px) and (max-width:1024px) {

    .About {
        padding-top: 100px;
    }

    .About .about_content {
        display: flex;
        align-items: center;
        padding: 2em;
        gap: 4em;
    }

    .About .text-con {
        align-items: start;
        padding: 0px;
        gap: 2em
    }

    .About .text-con>* {
        text-align: start;
    }

    .About .about_description {
        padding: 0;

    }

    .About .profile-image-con {
        background: var(--dark-black);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 250px;
        width: 250px;
        border-radius: 50%;
        flex-shrink: 0;
        outline: 1px solid white;
        outline-offset: 15px;

    }

    .About .profile-image-con img {
        height: 80%;
        width: 80%;

    }



}


/* Desktop and Large screens */

/*  1024px*/
@media (min-width: 1024px) {

    .About {
        position: relative;
        padding: 5rem 10rem;

    }

    .About .blue-circle {
        display: block;

    }

    .About .orange-circle,
    .About .grey-circle {
        display: none;
    }

    .About .blue-circle-about {
        display: block;
        position: relative;
        align-self: flex-end;
        position: relative;
        left: 160px;
        bottom: 20px;
    }

    .About .grey-circle-about {
        display: block;
        left: 400px;
        bottom: 0px;
        width: calc(14vw - 40px);
        position: absolute;
      

    }

    .About .orange-circle-about-no-tail {
        display: block;
        z-index: 50;
        width: 40px;
        height: 40px;
        background-color: white;
        border-radius: 50%;
    }


    .About .about_title {
        /* font-size: 5rem; */
        font-size:5.2vw;
        line-height: 5vw;
    }

    .About .about_content {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8em;
        padding-right: em
    }

    .About .text-con {
        align-items: start;
        justify-content: start;
        padding: 0px;
        gap: 2em;
    }

    .About .text-con>* {
        text-align: start;
    }

    .About .about_description {
        padding: 0em;
        padding-right: 10rem;

    }

    .About .profile-image-con {
        background: var(--dark-black);
        height: 500px;
        width: 500px;
        justify-content: center;
        border-radius: 50%;
        flex-shrink: 0;
        outline: 1px solid white;
        outline-offset: 25px;

    }

    .About .visual-side {
        display: flex;
        flex-direction: column;
    }


    .About .profile-image-con img {
        height: 100%;
        width: 100%;
        position: relative;
        bottom: 1.5em;

    }

    .About .my-project-btn-cont {
        /* border: 2px solid red; */
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        margin-top: 3em;

    }

}